
import ApiService from "@/core/services/ApiService";
import {defineComponent, onMounted, ref} from "vue";
import moment from "moment/moment";
import Currency from "@/components/common/Currency.vue";
import PaymentMethodLink from "@/components/common/PaymentMethodLink.vue";
import OrderStatus from "@/components/orders/OrderStatus.vue";
import DateTime from "@/components/common/DateTime.vue";

export default defineComponent({
  components: {DateTime, OrderStatus, PaymentMethodLink, Currency},
  props: {
    title: String,
    url: String,
    classes: String,
  },
  setup(props) {
    let widgetUrl = ref(props.url)
    let widgetData = ref({
      orders: {},
      total: 0
    })

    const fromDate = moment().subtract(1, 'year').format('YYYY-MM-DD')
    const toDate = moment().format('YYYY-MM-DD')

    onMounted(() => {
      ApiService.setHeader();
      ApiService.get(widgetUrl.value)
          .then(({data}) => {
            widgetData.value = data;
          })
          .catch(({response}) => {
            console.log(response);
          });
    })

    return {
      widgetData,
      widgetUrl,
      fromDate,
      toDate,
    }
  }
});
