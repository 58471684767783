
import {defineComponent} from "vue";
import {setCurrentPageTitle} from "@/core/helpers/breadcrumb";
import OrdersTableWidget from "@/views/dashboard/components/OrdersTableWidget.vue";
import SalesSummary from "@/views/dashboard/components/SalesSummary.vue";
import moment from "moment";

export default defineComponent({
  name: "dashboard",
  components: {
    SalesSummary,
    OrdersTableWidget: OrdersTableWidget
  },
  data() {
    return {
      startDate: '',
      endDate: '',
      currentView: 0,
    }
  },
  mounted() {
    this.days(0, 0)
    setCurrentPageTitle("Dashboard");
  },
  methods: {
    days(daysFrom, daysTo) {
      this.currentView = daysFrom
      this.startDate = moment().subtract(daysFrom, 'days').format('YYYY-MM-DD');
      this.endDate = moment().subtract(daysTo, 'days').format('YYYY-MM-DD');
    },
    currentMonth() {
      this.currentView = 31
      this.startDate = moment().format('YYYY-MM-01');
      this.endDate = moment().format('YYYY-MM-DD');
    }
  }
});
