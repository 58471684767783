import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card-header border-0 pt-5" }
const _hoisted_2 = { class: "card-title align-items-start flex-column" }
const _hoisted_3 = { class: "card-label fw-bolder fs-3 mb-1" }
const _hoisted_4 = { class: "card-body py-3" }
const _hoisted_5 = { class: "table-responsive" }
const _hoisted_6 = { class: "table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = { class: "align-text-top" }
const _hoisted_9 = { key: 0 }
const _hoisted_10 = ["href"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = ["href"]
const _hoisted_13 = { key: 2 }
const _hoisted_14 = ["href"]
const _hoisted_15 = { class: "align-text-top ff-tnum" }
const _hoisted_16 = { class: "align-text-top" }
const _hoisted_17 = { class: "align-text-top text-center ff-tnum" }
const _hoisted_18 = { class: "align-text-top" }
const _hoisted_19 = { class: "align-text-top text-end" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_OrderStatus = _resolveComponent("OrderStatus")!
  const _component_DateTime = _resolveComponent("DateTime")!
  const _component_PaymentMethodLink = _resolveComponent("PaymentMethodLink")!
  const _component_Currency = _resolveComponent("Currency")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.classes, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("h3", _hoisted_2, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.title), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("table", _hoisted_6, [
          _cache[4] || (_cache[4] = _createElementVNode("thead", null, [
            _createElementVNode("tr", { class: "fw-bolder text-muted" }, [
              _createElementVNode("th", { class: "min-w-140px" }, "Cliente"),
              _createElementVNode("th", { class: "min-w-140px" }, "Venda"),
              _createElementVNode("th", { class: "min-w-140px" }, "Situação"),
              _createElementVNode("th", { class: "min-w-140px text-center" }, "Data/Hora"),
              _createElementVNode("th", { class: "min-w-140px" }, "Forma de Pagamento"),
              _createElementVNode("th", { class: "min-w-140px text-end" }, "Valor")
            ])
          ], -1)),
          _createElementVNode("tbody", null, [
            (_ctx.widgetData.orders.total === 0)
              ? (_openBlock(), _createElementBlock("tr", _hoisted_7, _cache[0] || (_cache[0] = [
                  _createElementVNode("td", null, "Nenhuma venda", -1)
                ])))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.widgetData.orders.data, (order) => {
              return (_openBlock(), _createElementBlock("tr", {
                key: order.id
              }, [
                _createElementVNode("td", _hoisted_8, [
                  _createVNode(_component_router_link, {
                    to: `/apps/customers/customer-details/${order.user.id}`
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(order.user.email), 1)
                    ]),
                    _: 2
                  }, 1032, ["to"]),
                  (order.gateway && order.gateway.indexOf('pagarme') > -1)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                        _createElementVNode("a", {
                          href: `https://beta.dashboard.pagar.me/#/transactions?search=${order.user.email}`,
                          target: "_blank"
                        }, _cache[1] || (_cache[1] = [
                          _createTextVNode("Pagar.me "),
                          _createElementVNode("i", { class: "fad fa-external-link" }, null, -1)
                        ]), 8, _hoisted_10)
                      ]))
                    : _createCommentVNode("", true),
                  (order.gateway && order.gateway.indexOf('stripe') > -1)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_11, [
                        _createElementVNode("a", {
                          href: `https://dashboard.stripe.com/search?query=${order.user.email}`,
                          target: "_blank"
                        }, _cache[2] || (_cache[2] = [
                          _createTextVNode("Stripe "),
                          _createElementVNode("i", { class: "fad fa-external-link" }, null, -1)
                        ]), 8, _hoisted_12)
                      ]))
                    : _createCommentVNode("", true),
                  (order.gateway && order.gateway.indexOf('paypal') > -1)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
                        _createElementVNode("a", {
                          href: `https://www.paypal.com/activities/?fromDate=${_ctx.fromDate}&toDate=${_ctx.toDate}&searchType=ANY&searchKeyword=${order.user.email}&transactiontype=ALL_TRANSACTIONS&archive=INCLUDE_ARCHIVED_TRANSACTIONS`,
                          target: "_blank"
                        }, _cache[3] || (_cache[3] = [
                          _createTextVNode("PayPal "),
                          _createElementVNode("i", { class: "fad fa-external-link" }, null, -1)
                        ]), 8, _hoisted_14)
                      ]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("td", _hoisted_15, _toDisplayString(order.order_number), 1),
                _createElementVNode("td", _hoisted_16, [
                  _createVNode(_component_OrderStatus, {
                    status: order.status
                  }, null, 8, ["status"])
                ]),
                _createElementVNode("td", _hoisted_17, [
                  _createVNode(_component_DateTime, {
                    "date-time": order.created_at
                  }, null, 8, ["date-time"])
                ]),
                _createElementVNode("td", _hoisted_18, [
                  _createVNode(_component_PaymentMethodLink, { order: order }, null, 8, ["order"])
                ]),
                _createElementVNode("td", _hoisted_19, [
                  _createVNode(_component_Currency, {
                    code: order.currency.code,
                    amount: order.total
                  }, null, 8, ["code", "amount"])
                ])
              ]))
            }), 128))
          ])
        ])
      ])
    ])
  ], 2))
}