import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "card-body p-0" }
const _hoisted_2 = { class: "d-flex flex-stack" }
const _hoisted_3 = { class: "m-0 text-white fw-bolder fs-3" }
const _hoisted_4 = {
  class: "shadow-xs card-rounded mx-9 mb-9 px-6 pt-9 pb-2 position-relative z-index-1 bg-white",
  style: {"margin-top":"-100px"}
}
const _hoisted_5 = { class: "d-flex align-items-center flex-wrap w-100" }
const _hoisted_6 = { class: "mb-1 pe-3 flex-grow-1" }
const _hoisted_7 = {
  href: "#",
  class: "fs-5 text-gray-800 text-hover-primary fw-bolder"
}
const _hoisted_8 = { class: "d-flex align-items-center" }
const _hoisted_9 = { class: "fw-bolder fs-5 text-gray-800 pe-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass([_ctx.classes, "card"])
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: _normalizeClass([`bg-${_ctx.color}`, "px-9 pt-7 card-rounded h-175px w-100"])
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("h3", _hoisted_3, _toDisplayString(_ctx.title), 1)
        ])
      ], 2),
      _createElementVNode("div", _hoisted_4, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.widgetData.orders_totals, (amount, currency, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
            (amount > 0)
              ? (_openBlock(), _createElementBlock("div", {
                  key: 0,
                  class: _normalizeClass([[index !== _ctx.widgetData.orders_totals.length && 'mb-7'], "d-flex align-items-center"])
                }, [
                  _createElementVNode("div", _hoisted_5, [
                    _createElementVNode("div", _hoisted_6, [
                      _createElementVNode("a", _hoisted_7, _toDisplayString(currency), 1)
                    ]),
                    _createElementVNode("div", _hoisted_8, [
                      _createElementVNode("div", _hoisted_9, _toDisplayString(new Intl.NumberFormat('pt-BR', {style: 'currency', currency: currency}).format(amount)), 1)
                    ])
                  ])
                ], 2))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ])
    ])
  ], 2))
}