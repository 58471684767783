import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col" }
const _hoisted_3 = { class: "col" }
const _hoisted_4 = { class: "col" }
const _hoisted_5 = { class: "col" }
const _hoisted_6 = { class: "col" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = { class: "col col-lg-6" }
const _hoisted_9 = { class: "col col-lg-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SalesSummary = _resolveComponent("SalesSummary")!
  const _component_OrdersTableWidget = _resolveComponent("OrdersTableWidget")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("button", {
          class: _normalizeClass(["btn btn-lg w-100 mb-5", {'btn-success': _ctx.currentView === 0, 'btn-dark': _ctx.currentView !== 0}]),
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.days(0, 0)))
        }, "Hoje", 2)
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("button", {
          class: _normalizeClass(["btn btn-lg w-100 mb-5", {'btn-success': _ctx.currentView === 1, 'btn-dark': _ctx.currentView !== 1}]),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.days(1, 1)))
        }, "Ontem", 2)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("button", {
          class: _normalizeClass(["btn btn-lg w-100 mb-5", {'btn-success': _ctx.currentView === 7, 'btn-dark': _ctx.currentView !== 7}]),
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.days(7, 0)))
        }, "7 Dias", 2)
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("button", {
          class: _normalizeClass(["btn btn-lg w-100 mb-5", {'btn-success': _ctx.currentView === 30, 'btn-dark': _ctx.currentView !== 30}]),
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.days(30, 0)))
        }, "30 Dias", 2)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("button", {
          class: _normalizeClass(["btn btn-lg w-100 mb-5", {'btn-success': _ctx.currentView === 31, 'btn-dark': _ctx.currentView !== 31}]),
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.currentMonth()))
        }, "Mês Atual", 2)
      ])
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        (_openBlock(), _createBlock(_component_SalesSummary, {
          classes: "mb-5 mb-xl-8",
          color: "success",
          title: "Total - Pagas",
          url: `v1/backend/orders?status=1&start_date=${_ctx.startDate}&end_date=${_ctx.endDate}`,
          key: `v1/backend/orders?status=1&start_date=${_ctx.startDate}&end_date=${_ctx.endDate}`
        }, null, 8, ["url"]))
      ]),
      _createElementVNode("div", _hoisted_9, [
        (_openBlock(), _createBlock(_component_SalesSummary, {
          classes: "mb-5 mb-xl-8",
          color: "warning",
          title: "Total - Aguardando Pagamento",
          url: `v1/backend/orders?status=0&start_date=${_ctx.startDate}&end_date=${_ctx.endDate}`,
          key: `v1/backend/orders?status=0&start_date=${_ctx.startDate}&end_date=${_ctx.endDate}`
        }, null, 8, ["url"]))
      ])
    ]),
    (_openBlock(), _createBlock(_component_OrdersTableWidget, {
      classes: "mb-5 mb-xl-8",
      url: `v1/backend/orders?limit=10000&status=1&start_date=${_ctx.startDate}&end_date=${_ctx.endDate}`,
      key: `v1/backend/orders?limit=10000&status=1&start_date=${_ctx.startDate}&end_date=${_ctx.endDate}`,
      title: "Vendas - Pagas"
    }, null, 8, ["url"])),
    (_openBlock(), _createBlock(_component_OrdersTableWidget, {
      classes: "mb-5 mb-xl-8",
      url: `v1/backend/orders?limit=10000&status=0&start_date=${_ctx.startDate}&end_date=${_ctx.endDate}`,
      key: `v1/backend/orders?limit=10000&status=0&start_date=${_ctx.startDate}&end_date=${_ctx.endDate}`,
      title: "Vendas - Aguardando Pagamento"
    }, null, 8, ["url"])),
    (_openBlock(), _createBlock(_component_OrdersTableWidget, {
      classes: "mb-5 mb-xl-8",
      url: `v1/backend/orders?limit=10000&status=2&start_date=${_ctx.startDate}&end_date=${_ctx.endDate}`,
      key: `v1/backend/orders?limit=10000&status=2&start_date=${_ctx.startDate}&end_date=${_ctx.endDate}`,
      title: "Vendas - Canceladas"
    }, null, 8, ["url"]))
  ]))
}